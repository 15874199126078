
import { defineComponent } from "@vue/runtime-core";

import consentContent from "@/domains/safewa/assets/content/consent.md";

import Mobile from "@/components/templates/Mobile.vue";
import GvMarkdown from "@/components/gv/gvMarkdown/gvMarkdown.vue";
import GvButton from "@/components/gv/gvButton/gvButton.vue";

export default defineComponent({
  name: "Consent",
  components: {
    Mobile,
    GvMarkdown,
    GvButton,
  },
  setup() {
    const handoffUrl = "/authorise/basic/handoff";
    const onDecline = () => {
      window.location.href = `${handoffUrl}?result=declined`;
    };
    return {
      consentContent,
      onDecline,
    };
  },
});
