import { RouteRecordRaw } from "vue-router";
import Decommission from "../views/Decommission.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/service/vaccination",
    redirect: "/service/vaccination/decommission",
  },
  {
    path: "/service/vaccination/decommission",
    component: Decommission,
  },
];
