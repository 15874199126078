<template>
  <div class="w-full flex items-center justify-center">
    <div class="flex flex-col w-full min-h-screen sm:h-3/4 sm:w-96 py-6">
      <div class="flex border-b border-[#EFF0F2] mb-6">
        <slot name="header" />
      </div>

      <div class="px-6 pb-6">
        <slot />
      </div>

      <template v-if="$slots.footer">
        <div class="w-full border-t border-grey-50 mt-auto" />

        <div class="px-6 pt-6">
          <slot name="footer" />
        </div>
      </template>
    </div>
  </div>
</template>
