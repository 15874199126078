
import { defineComponent } from "vue";
import { event } from "vue-gtag";
import platform from "platform";

import Restrict from "@/components/Restrict.vue";

export default defineComponent({
  name: "Redirect",
  components: {
    Restrict,
  },
  mounted() {
    let redirectUrl =
      "https://www.wa.gov.au/organisation/government-of-western-australia/servicewa-mobile-app";

    if (
      platform.name?.includes("Samsung") ||
      platform.name?.includes("Firefox")
    ) {
      redirectUrl =
        "https://www.wa.gov.au/organisation/government-of-western-australia/having-trouble-sharing-your-covid-19-digital-certificate-the-servicewa-app#are-you-using-a-samsung-phone-browser-or-the-firefox-browser";
    }

    event("pinnacle_redirect", {
      redirectUrl,
      platformName: platform.name,
    });

    window.location.replace(redirectUrl);
  },
});
