import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gv_markdown = _resolveComponent("gv-markdown")!
  const _component_gv_button = _resolveComponent("gv-button")!
  const _component_mobile = _resolveComponent("mobile")!

  return (_openBlock(), _createBlock(_component_mobile, null, {
    default: _withCtx(() => [
      _createVNode(_component_gv_markdown, { markdown: _ctx.aboutContent }, null, 8, ["markdown"])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_gv_button, {
          text: "Next",
          size: "lg",
          to: "/service/safewa/consent"
        })
      ])
    ]),
    _: 1
  }))
}