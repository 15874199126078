import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gv_markdown = _resolveComponent("gv-markdown")!

  return (_ctx.scopes && _ctx.scopes.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scopes, (scope) => {
          return (_openBlock(), _createElementBlock("div", { key: scope }, [
            _createVNode(_component_gv_markdown, {
              class: "bg-grey-25 p-4",
              key: `${scope}-desc`,
              markdown: _ctx.scopeDescriptions[scope]
            }, null, 8, ["markdown"]),
            _createVNode(_component_gv_markdown, {
              class: "py-4",
              key: `${scope}-consent`,
              markdown: _ctx.consentMarkdownContent[scope]
            }, null, 8, ["markdown"])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}