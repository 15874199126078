
import { computed, defineComponent, PropType } from "vue";

import { Scheme, Size } from "./types";

import { name } from "./constants";

interface Classes {
  container: string;
  text: string;
}

export default defineComponent({
  name,
  props: {
    text: String,
    scheme: {
      type: String as PropType<Scheme>,
      default: "info",
    },
    size: {
      type: String as PropType<Size>,
      default: "md",
    },
  },
  setup(props) {
    const schemeClasses: Record<Scheme, Classes> = {
      info: {
        container: "bg-blue-50",
        text: "text-blue-600",
      },
      success: {
        container: "bg-status-success-bg",
        text: "text-status-success",
      },
      warning: {
        container: "bg-status-warning-bg",
        text: "text-status-warning",
      },
      error: {
        container: "bg-status-error-bg",
        text: "text-status-error",
      },
    };

    const sizeClasses: Record<Size, Classes> = {
      sm: {
        container: "p-3",
        text: "",
      },
      md: {
        container: "p-4",
        text: "",
      },
      lg: {
        container: "p-5",
        text: "",
      },
    };

    return {
      schemeClass: computed(() => schemeClasses[props.scheme]),
      sizeClass: computed(() => sizeClasses[props.size]),
    };
  },
});
