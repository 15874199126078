import { ref, computed, watch, reactive } from "vue";

export enum ProcessStatus {
  PENDING = "pending",
  LOADING = "loading",
  SUCCESS = "success",
  FAILED = "failed",
}

export interface ProcessOptions {
  name?: string;
  initialStatus?: ProcessStatus;
}

export interface Process {
  status: ProcessStatus;
  error?: string;
  loading: boolean;
  options?: ProcessOptions;
  reset: () => void;
}

export const useProcess = (options?: ProcessOptions): Process => {
  const status = ref<ProcessStatus>(ProcessStatus.PENDING);
  const error = ref<string>();

  const applyInitialValues = () => {
    status.value = options?.initialStatus ?? ProcessStatus.PENDING;
    error.value = undefined;
  };

  const reset = () => {
    applyInitialValues();
  };

  // Set the status to failed when an error is present
  watch(error, () => {
    if (error.value) {
      status.value = ProcessStatus.FAILED;
    }
  });

  reset();

  return reactive({
    status,
    error,
    loading: computed(() => status.value === ProcessStatus.LOADING),
    options,
    reset,
  });
};
