import { RouteRecordRaw } from "vue-router";

import About from "@/domains/safewa/views/About.vue";
import Consent from "@/domains/safewa/views/Consent.vue";
import Link from "@/domains/safewa/views/Link.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/service/safewa",
    redirect: "/service/safewa/about",
  },
  {
    path: "/service/safewa/about",
    component: About,
  },
  {
    path: "/service/safewa/consent",
    component: Consent,
  },
  {
    path: "/service/safewa/link",
    component: Link,
  },
];
