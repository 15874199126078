// Meta
export const name = "gvButton";

// Props
export const schemes = [
  "primary",
  "secondary",
  "accent",
  "grey",
  "custom",
] as const;
export const variants = ["solid", "outline", "text", "custom"] as const;
export const sizes = ["sm", "md", "lg", "custom"] as const;
export const paddings = [...sizes, "xl"] as const;
