import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gv_type = _resolveComponent("gv-type")!

  return (!_ctx.isEmpty)
    ? (_openBlock(), _createElementBlock("label", {
        key: 0,
        class: _normalizeClass(["inline-flex", `${_ctx.schemeClass.container} ${_ctx.alignClass.container} ${_ctx.sideClass.container}`])
      }, [
        (_ctx.isPre)
          ? _renderSlot(_ctx.$slots, "default", { key: 0 })
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "label", {}, () => [
          (_ctx.text)
            ? (_openBlock(), _createBlock(_component_gv_type, {
                key: 0,
                variant: "heading",
                scheme: "custom",
                class: _normalizeClass(`${_ctx.schemeClass.text} ${_ctx.sideClass.text}`)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.text), 1)
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true)
        ]),
        (!_ctx.isPre)
          ? _renderSlot(_ctx.$slots, "default", { key: 1 })
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}