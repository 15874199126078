
import { computed, defineComponent, ref } from "vue";
import qs from "qs";

import { usePinnacleService } from "@/services/pinnacle";
import { ProcessStatus, useProcess } from "@/composables/process";

import {
  ConsentFlow,
  useAuthorise,
} from "@/domains/auth/composables/authorise";

import Mobile from "@/components/templates/Mobile.vue";
import Scopes from "@/domains/auth/components/Scopes.vue";

import GvLabel from "@/components/gv/gvLabel/gvLabel.vue";
import GvType from "@/components/gv/gvType/gvType.vue";
import GvNotice from "@/components/gv/gvNotice/gvNotice.vue";
import GvButton from "@/components/gv/gvButton/gvButton.vue";

export default defineComponent({
  name: "Basic",
  components: {
    Mobile,
    Scopes,
    GvLabel,
    GvType,
    GvButton,
    GvNotice,
  },
  async setup() {
    const { authorise, authoriseProcess } = await useAuthorise();

    if (!authorise) {
      return {
        authoriseProcess,
      };
    }

    const handoffUrl = "/authorise/basic/handoff";
    const optionalScopes = ref<string[]>([]);
    const showRequiredScopes = computed(
      () => authorise?.flow === ConsentFlow.link
    );
    const pinnacleService = usePinnacleService({ token: authorise.token });
    const acceptProcess = useProcess();

    const onDecline = () => {
      window.location.href = `${handoffUrl}?result=declined`;
    };

    const onAccept = async () => {
      try {
        acceptProcess.reset();
        acceptProcess.status = ProcessStatus.LOADING;

        const { linkingUrl } = authorise.serviceProps;

        if (!linkingUrl) {
          throw new Error("No linking URL supplied by this service.");
        }

        const scopes = [
          ...authorise.serviceProps.scopes,
          ...optionalScopes.value,
        ];

        const res = await pinnacleService.postLinkScopes({
          serviceName: authorise.serviceName,
          scopes,
        });

        if (!res) {
          throw new Error("No valid response from link scopes request.");
        }

        if (authorise?.flow === ConsentFlow.update) {
          window.location.href = `${handoffUrl}?${qs.stringify({
            result: "accepted",
            scopes,
          })}`;
        } else {
          const { token } = res;

          if (!token || token.length <= 0) {
            throw new Error("Invalid token returned.");
          }

          const queryString = qs.stringify({
            token,
            onSuccessRedirect: `${window.location.origin}/${handoffUrl}?result=accepted`,
          });
          const redirectUrl = `${linkingUrl}?${queryString}`;

          window.location.href = redirectUrl;
        }

        acceptProcess.status = ProcessStatus.SUCCESS;
      } catch (error) {
        acceptProcess.error = error as string;
      }
    };

    return {
      showRequiredScopes,
      optionalScopes,
      authorise,
      acceptProcess,
      onAccept,
      onDecline,
    };
  },
});
