import { computed, ComputedRef } from "vue";

import { Env } from "@/types/environment";

const envs: Record<string, Env> = {
  localhost: "dev",
  "genvis.com": "dev",
  "wa.gov.au": "prd",
};

export const useEnv = (): ComputedRef<Env> => {
  const env = computed<Env>(() => {
    const { hostname } = location;

    const env: Env =
      Object.entries(envs).find(([envUrl]) => hostname.endsWith(envUrl))?.[1] ??
      "dev";

    return env;
  });

  return env;
};
