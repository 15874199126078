import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gv_type = _resolveComponent("gv-type")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.is), {
    class: _normalizeClass(["gvButton rounded-lg inline-flex items-center transition-[box-shadow_background-color] focus-visible:outline-none focus-visible:ring-offset-2 focus-visible:ring-2 disabled:cursor-default", `${_ctx.schemeVariantClass} ${_ctx.variantClass} ${_ctx.sizeClass} ${_ctx.paddingClass} ${_ctx.squareClass}`]),
    href: _ctx.href,
    to: _ctx.to,
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_component_gv_type, {
          class: "w-full",
          scheme: "custom",
          align: "center",
          wrap: "nowrap"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.text), 1)
          ]),
          _: 1
        })
      ], true)
    ]),
    _: 3
  }, 8, ["class", "href", "to", "disabled"]))
}