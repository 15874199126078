import { RouteRecordRaw } from "vue-router";

// Domains
import { routes as appRoutes } from "@/domains/app/router/routes";
import { routes as authRoutes } from "@/domains/auth/router/routes";
import { routes as safewaRoutes } from "@/domains/safewa/router/routes";
import { routes as vaccinationRoutes } from "@/domains/vaccination/router/routes";

export const routes: Array<RouteRecordRaw> = [
  ...appRoutes,
  ...authRoutes,
  ...safewaRoutes,
  ...vaccinationRoutes,
];
