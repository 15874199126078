import { computed, reactive } from "vue";

import { Env } from "@/types/environment";

import { Config, configs } from "@/config/configs";
import { useEnv } from "@/composables/environment";

type UseConfig = {
  env: Env;
  config: Config;
};

export const useConfig = (): UseConfig => {
  const env = useEnv();
  const config = computed(() => configs[env.value]);

  return reactive({
    env,
    config,
  });
};
