import { Env } from "@/types/environment";

export type Config = {
  pinnacleUrl: string;
  appSchemaUrl: string;
};

export const configs: Record<Env, Config> = {
  dev: {
    pinnacleUrl: "https://api.dpc.dev.genvis.com",
    appSchemaUrl: "servicewadev://dpc.dev.genvis.com/app/",
  },
  uat: {
    pinnacleUrl: "https://api.dpc.uat.mobile.service.digital.wa.gov.au",
    appSchemaUrl:
      "servicewauat://uat.servicewa.mobile.service.digital.wa.gov.au/app/",
  },
  prd: {
    pinnacleUrl: "https://api.servicewa.mobile.service.digital.wa.gov.au",
    appSchemaUrl: "servicewa://servicewa.mobile.service.digital.wa.gov.au/app/",
  },
};
