
import { defineComponent, computed, PropType } from "vue";

import { Scheme, Variant, Size, Wrap, Align } from "./types";

import { name } from "./constants";

export default defineComponent({
  name,
  props: {
    text: String,
    scheme: {
      type: String as PropType<Scheme>,
      default: "primary",
    },
    variant: {
      type: String as PropType<Variant>,
      default: "body-md",
    },
    size: {
      type: String as PropType<Size>,
      default: "content",
    },
    wrap: {
      type: String as PropType<Wrap>,
      default: "wrap",
    },
    align: {
      type: String as PropType<Align>,
      default: "left",
    },
  },
  setup(props, { slots }) {
    const isEmpty = computed(() =>
      slots.default || props.text ? false : true
    );

    const schemeClasses: Record<Scheme, string> = {
      primary: "text-type-primary",
      secondary: "text-type-secondary",
      tertiary: "text-type-tertiary",
      custom: "",
    };

    const variantClasses: Record<Variant, string> = {
      "display-xl": "text-3xl sm:text-6xl font-visby font-extrabold",
      "display-lg": "text-2xl sm:text-4xl font-visby font-extrabold",
      "display-md": "text-lg sm:text-2xl font-visby font-bold",
      "display-sm": "text-base sm:text-xl font-proxima-nova font-normal",
      "subtitle-lg": "text-2xl sm:text-[2rem] leading-10 font-proxima-nova",
      "subtitle-md": "text-xl sm:text-2xl font-proxima-nova",
      "subtitle-sm": "text-lg sm:text-xl font-proxima-nova",
      heading: "text-base font-proxima-nova leading-6 font-semibold",
      subheading: "text-xs font-proxima-nova leading-4 font-semibold uppercase",
      button: "text-sm font-proxima-nova leading-6 font-medium",
      "body-lg": "text-base sm:text-lg font-proxima-nova leading-6",
      "body-md": "text-base font-proxima-nova leading-6",
      "body-sm": "text-sm font-proxima-nova leading-6",
      "body-xs": "text-xs font-proxima-nova leading-6",
      caption: "text-xs font-proxima-nova leading-4",
    };

    const variantToElement: Record<Variant, string> = {
      "display-xl": "h1",
      "display-lg": "h2",
      "display-md": "h3",
      "display-sm": "h4",
      "subtitle-lg": "h3",
      "subtitle-md": "h4",
      "subtitle-sm": "h5",
      heading: "h5",
      subheading: "h6",
      button: "p",
      "body-lg": "p",
      "body-md": "p",
      "body-sm": "p",
      "body-xs": "p",
      caption: "caption",
    };

    const wrapClasses: Record<Wrap, string> = {
      wrap: "whitespace-normal",
      nowrap: "whitespace-nowrap",
      truncate: "truncate",
    };

    const sizeClasses: Record<Size, string> = {
      full: "inline w-full",
      content: "block",
    };

    const alignClasses: Record<Align, string> = {
      left: "text-left",
      center: "text-center",
      right: "text-right",
    };

    return {
      isEmpty,
      is: computed(() => variantToElement[props.variant]),
      schemeClass: computed(() => schemeClasses[props.scheme]),
      variantClass: computed(() => variantClasses[props.variant]),
      wrapClass: computed(() => wrapClasses[props.wrap]),
      sizeClass: computed(() => sizeClasses[props.size]),
      alignClass: computed(() => alignClasses[props.align]),
    };
  },
});
