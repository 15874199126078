import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isLoading)
      ? _renderSlot(_ctx.$slots, "loading", { key: 0 })
      : (_ctx.isFallback)
        ? _renderSlot(_ctx.$slots, "fallback", { key: 1 })
        : _createCommentVNode("", true),
    (!_ctx.isFallback)
      ? _withDirectives((_openBlock(), _createElementBlock("img", {
          key: `${_ctx.currentSource}-${_ctx.sourceIndex}`,
          src: _ctx.currentSource,
          alt: _ctx.alt,
          class: _normalizeClass(["w-full h-full", 
        {
          fill: 'object-fill',
          contain: 'object-contain',
          cover: 'object-cover',
        }[_ctx.fit]
      ]),
          onError: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onError && _ctx.onError(...args))),
          onLoad: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onLoad && _ctx.onLoad(...args)))
        }, null, 42, _hoisted_1)), [
          [_vShow, _ctx.isSuccess]
        ])
      : _createCommentVNode("", true)
  ]))
}