
import { defineComponent, PropType } from "@vue/runtime-core";

import GvMarkdown from "@/components/gv/gvMarkdown/gvMarkdown.vue";

export default defineComponent({
  components: { GvMarkdown },
  name: "Scopes",
  props: {
    scopes: {
      required: true,
      type: Array as PropType<string[]>,
    },
    scopeDescriptions: {
      required: true,
      type: Object as PropType<Record<string, string>>,
    },
    consentMarkdownContent: {
      required: true,
      type: Object as PropType<Record<string, string>>,
    },
  },
});
