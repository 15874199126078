
import { defineComponent } from "@vue/runtime-core";

import aboutContent from "@/domains/safewa/assets/content/about.md";

import Mobile from "@/components/templates/Mobile.vue";
import GvMarkdown from "@/components/gv/gvMarkdown/gvMarkdown.vue";

export default defineComponent({
  name: "About",
  components: {
    Mobile,
    GvMarkdown,
  },
  setup() {
    return {
      aboutContent,
    };
  },
});
