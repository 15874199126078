// Meta
export const name = "gvType";

// Props
export const schemes = ["primary", "secondary", "tertiary", "custom"] as const;
export const variants = [
  "display-xl",
  "display-lg",
  "display-md",
  "display-sm",
  "subtitle-lg",
  "subtitle-md",
  "subtitle-sm",
  "heading",
  "subheading",
  "button",
  "body-lg",
  "body-md",
  "body-sm",
  "body-xs",
  "caption",
] as const;
export const wraps = ["wrap", "nowrap", "truncate"] as const;
export const sizes = ["full", "content"] as const;
export const aligns = ["left", "center", "right"] as const;
