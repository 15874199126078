import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gv_type = _resolveComponent("gv-type")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["rounded-lg", `${_ctx.schemeClass.container} ${_ctx.sizeClass.container}`])
  }, [
    _createVNode(_component_gv_type, {
      scheme: "custom",
      class: _normalizeClass(`${_ctx.schemeClass.text} ${_ctx.sizeClass.text}`)
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.text), 1)
        ])
      ]),
      _: 3
    }, 8, ["class"])
  ], 2))
}