
import { defineComponent } from "vue";

import Mobile from "@/components/templates/Mobile.vue";
import { useRoute } from "vue-router";

import GvImage from "@/components/gv/gvImage/gvImage.vue";
import GvType from "@/components/gv/gvType/gvType.vue";

export default defineComponent({
  components: { Mobile, GvImage, GvType },
  name: "NotFound",
  setup() {
    const route = useRoute();
    const { serviceName } = route.query;
    return {
      serviceName,
    };
  },
});
