import { RouteRecordRaw } from "vue-router";

import Basic from "@/domains/auth/views/Basic.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/authorise/basic",
    component: Basic,
  },
];
