import { createApp } from "vue";
// import GenvisFrontend from "@genvis/frontend";
import VueGtag from "vue-gtag";

import App from "@/App.vue";

import router from "@/router";

const VueApp = createApp(App);

VueApp.use(router);
// VueApp.use(GenvisFrontend);

// Google analytics
VueApp.use(VueGtag, {
  config: {
    id: "G-5GJFC56C63",
  },
});

VueApp.mount("#app");
