import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "px-6" }
const _hoisted_2 = { class: "flex flex-col h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gv_image = _resolveComponent("gv-image")!
  const _component_gv_markdown = _resolveComponent("gv-markdown")!
  const _component_gv_button = _resolveComponent("gv-button")!
  const _component_mobile = _resolveComponent("mobile")!

  return (_openBlock(), _createBlock(_component_mobile, null, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_gv_image, {
          fit: "cover",
          class: "mb-6 h-10 w-20",
          src: require('@/assets/images/header/swa-logo.png')
        }, null, 8, ["src"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_gv_markdown, { markdown: _ctx.decommissionContent }, null, 8, ["markdown"]),
        _createVNode(_component_gv_button, {
          class: "mt-6",
          text: "Back to ServiceWA",
          onClick: _ctx.returnToMobileApp
        }, null, 8, ["onClick"])
      ])
    ]),
    _: 1
  }))
}