
import { defineComponent, onMounted } from "@vue/runtime-core";

import Mobile from "@/components/templates/Mobile.vue";
import GvType from "@/components/gv/gvType/gvType.vue";

export default defineComponent({
  name: "Link",
  components: {
    Mobile,
    GvType,
  },
  setup() {
    onMounted(() => {
      window.location.href = "/authorise/basic/handoff?result=accepted";
    });
  },
});
