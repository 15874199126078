
import { defineComponent, computed } from "vue";
import showdown from "showdown";
import dedent from "dedent";

import { name } from "./constants";

export default defineComponent({
  name,
  props: {
    markdown: {
      type: String,
    },
    tag: {
      type: String,
      required: false,
      default: "div",
    },
  },
  setup(props, { slots }) {
    const convertor = new showdown.Converter({
      emoji: true,
      strikethrough: true,
    });

    const inputMarkdown = computed<string>(() => {
      let input = "";

      const slot = slots?.default?.()[0];

      if (slot) {
        input = (slot.children as string).trim();
      }

      if (props.markdown) {
        input = props.markdown;
      }

      return dedent(input);
    });

    const outputHtml = computed(() => convertor.makeHtml(inputMarkdown.value));

    return {
      outputHtml,
    };
  },
});
