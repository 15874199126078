import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.isEmpty)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.is), {
        key: 0,
        class: _normalizeClass(`${_ctx.schemeClass} ${_ctx.variantClass} ${_ctx.wrapClass} ${_ctx.sizeClass} ${_ctx.alignClass}`)
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.text), 1)
          ])
        ]),
        _: 3
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}