import ky from "ky";
import qs from "qs";

import { useConfig } from "@/config";

import {
  GetServiceLinkPropsReq,
  GetServiceLinkPropsRes,
  PostServiceLinkScopesReq,
  PostServiceLinkScopesRes,
} from "./types";

interface Options {
  token?: string;
}

export const usePinnacleService = (options?: Options) => {
  const { config } = useConfig();

  const instance = ky.extend({
    prefixUrl: config.pinnacleUrl,

    ...(options?.token
      ? {
          headers: {
            Authorization: `Bearer ${options.token}`,
          },
        }
      : {}),
  });

  const getServiceLinkProps = async (queryObject: GetServiceLinkPropsReq) => {
    try {
      const queryString = qs.stringify(queryObject);

      const res = await instance.get(
        `api/v1/provider/service-link-props?${queryString}`
      );

      return (await res.json()) as GetServiceLinkPropsRes;
    } catch (error) {
      console.log(error);
    }
  };

  const postLinkScopes = async (json: PostServiceLinkScopesReq) => {
    try {
      const res = await instance.post("api/v1/provider/link-scopes", { json });

      return (await res.json()) as PostServiceLinkScopesRes;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    getServiceLinkProps,
    postLinkScopes,
  };
};
