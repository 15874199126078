import { RouteRecordRaw } from "vue-router";

import Redirect from "@/domains/app/views/Redirect.vue";

import NotFound from "@/domains/app/views/NotFound.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/app/pinnacle",
    component: Redirect,
  },
  // Alias
  {
    path: "/app/vaccine-certificate",
    component: Redirect,
  },
  {
    path: "/not-found",
    component: NotFound,
  },
];
