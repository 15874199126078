import { useRoute } from "vue-router";

import { ServiceProps } from "@/types/serviceProps";

import { usePinnacleService } from "@/services/pinnacle";
import { Process, ProcessStatus, useProcess } from "@/composables/process";

export enum ConsentFlow {
  link = "link",
  update = "update",
}

type UseAuthorise = {
  authorise?: {
    token: string;
    serviceName: string;
    flow: ConsentFlow;
    serviceProps: ServiceProps;
    hasScopes: boolean;
    hasOptionalScopes: boolean;
  };
  authoriseProcess: Process;
};

export const useAuthorise = async (): Promise<UseAuthorise> => {
  const route = useRoute();
  const authoriseProcess = useProcess({ initialStatus: ProcessStatus.LOADING });

  try {
    const token = route.query?.token as string;
    const serviceName = route.query?.serviceName as string;
    const flow = route.query?.flow as ConsentFlow;
    const DEFAULT_LINK_ERROR_MESSAGE = `There was an issue while attempting to enable your ${serviceName} account. Please try again.`;

    if (!token) {
      throw new Error(DEFAULT_LINK_ERROR_MESSAGE);
    }

    if (!serviceName || serviceName.length <= 0) {
      throw new Error(DEFAULT_LINK_ERROR_MESSAGE);
    }

    if (!flow || ![ConsentFlow.link, ConsentFlow.update].includes(flow)) {
      throw new Error(DEFAULT_LINK_ERROR_MESSAGE);
    }

    const pinnacleService = usePinnacleService({ token });

    const serviceProps = await pinnacleService.getServiceLinkProps({
      serviceName,
    });

    if (!serviceProps) {
      throw new Error(
        `There was an issue while attempting to enable your ${serviceName} account. Please try again, by logging out of and then logging back into the ServiceWA app.`
      );
    }

    const hasScopes = serviceProps.scopes.length > 0;
    const hasOptionalScopes = serviceProps.optionalScopes.length > 0;

    return {
      authorise: {
        token,
        serviceName,
        flow,
        serviceProps,
        hasScopes,
        hasOptionalScopes,
      },
      authoriseProcess,
    };
  } catch (error) {
    authoriseProcess.error = error as string;

    return {
      authoriseProcess,
    };
  }
};
