
import { defineComponent, computed, PropType } from "vue";

import { Scheme, Align, Side } from "./types";

import { name } from "./constants";

export interface Classes {
  container: string;
  text: string;
}

export default defineComponent({
  name,
  props: {
    text: String,
    for: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    scheme: {
      type: String as PropType<Scheme>,
      default: "primary",
    },
    align: {
      type: String as PropType<Align>,
      default: "start",
    },
    side: {
      type: String as PropType<Side>,
      default: "top",
    },
  },
  setup(props, { slots }) {
    const schemeClasses: Record<Scheme, Partial<Classes>> = {
      primary: {
        container: "text-type-primary",
      },
      secondary: {
        container: "text-type-secondary",
      },
      tertiary: {
        container: "text-type-tertiary",
      },
    };

    const alignClasses: Record<Align, Partial<Classes>> = {
      start: {
        container: "items-start",
      },
      center: {
        container: "items-center",
      },
      end: {
        container: "items-end",
      },
    };

    const sideClasses: Record<Side, Partial<Classes>> = {
      top: {
        container: "flex-col",
        text: "pb-1",
      },
      right: {
        container: "items-start",
        text: "pl-2",
      },
      bottom: {
        container: "flex-col",
        text: "pt-1",
      },
      left: {
        container: "items-start",
        text: "pr-2",
      },
    };

    return {
      isEmpty: computed(() =>
        slots.default || slots.label || props.text ? false : true
      ),
      isPre: computed(() => ["bottom", "right"].includes(props.side)),
      schemeClass: computed(() => schemeClasses[props.scheme]),
      alignClass: computed(() => alignClasses[props.align]),
      sideClass: computed(() => sideClasses[props.side]),
    };
  },
});
