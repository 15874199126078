
import { defineComponent } from "@vue/runtime-core";

import decommissionContent from "@/domains/vaccination/assets/content/decommission.md";

import Mobile from "@/components/templates/Mobile.vue";
import GvMarkdown from "@/components/gv/gvMarkdown/gvMarkdown.vue";
import GvImage from "@/components/gv/gvImage/gvImage.vue";
import GvButton from "@/components/gv/gvButton/gvButton.vue";
import { useConfig } from "@/config";

export default defineComponent({
  name: "Decommission",
  components: {
    Mobile,
    GvMarkdown,
    GvImage,
    GvButton,
  },
  setup() {
    const { config } = useConfig();
    const returnToMobileApp = () => {
      window.location.href =
        config.appSchemaUrl ?? "servicewadev://dpc.dev.genvis.com/app/";
    };
    return {
      decommissionContent,
      returnToMobileApp,
    };
  },
});
